import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { AnnouncesService } from 'src/app/shared/services/announces.service';
import { SystemService } from 'src/app/shared/services/system.service';

import { SharedModule } from 'src/app/shared/shared.module';

/** Http interceptor providers in outside-in order */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenInterceptor } from './interceptors/auth.interceptor';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ErrorLayoutComponent } from './layouts/error-layout/error-layout.component';
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenInterceptor,
    multi: true
  },
];

@NgModule({
  declarations: [
    MainLayoutComponent,
    ErrorLayoutComponent,
  ],
  imports: [
    // Vendor
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // Theme: https://ng.ant.design
    NgZorroAntdModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    Title,
    httpInterceptorProviders,
    ApiService,
    AuthService,
    GoogleAnalyticsService,
    AnnouncesService,
    SystemService,
  ],
  exports: [
    MainLayoutComponent,
    ErrorLayoutComponent,
  ],
})
export class CoreModule { }
