export interface NavLink {
  display: string;
  link: string;
  privilege: number;
  groups?: Array<string>;
  isAdmin?: boolean;
  icon: string;
  module?: string;
}

// Normal user
export const NavLinks: NavLink[] = [
//   {
//       display: 'บันทึกเวลาเข้า-ออกงาน',
//       link: '/clockin',
//       privilege: 5,
//       icon: 'clock-circle',
//       module: 'timestamp',
//   },
  {
      display: 'บันทึกงาน (Work Tasks)',
      link: '/worktask',
      privilege: 5,
      icon: 'form',
      module: 'worktask',
  },
  {
      display: 'สรุปผลการตรวจสุขภาพ',
      link: '/covid19',
      privilege: 5,
      icon: 'medicine-box',
      module: 'covid',
  },
  {
      display: 'ประกาศ',
      link: '/announces',
      privilege: 5,
      icon: 'container',
      module: 'timestamp',
  },
  {
      display: 'ค้นหาพนักงาน',
      link: '/staffs',
      privilege: 5,
      icon: 'team',
      module: 'timestamp',
  },
  {
      display: 'การจองทรัพย์สิน',
      link: '/reservation',
      privilege: 5,
      icon: 'schedule',
      module: 'reservation',
  },
  {
      display: 'ระบบลา',
      link: '/leaving/leave',
      privilege: 5,
      icon: 'file-protect',
      module: 'leave',
  },
];

export const OneServiceLinks: NavLink[] = [
  {
      display: 'OneChat',
      link: 'https://chat.one.th/login',
      privilege: 5,
      icon: 'https://chat.one.th/static/img/icons/logoonechat.png',
  },
  {
      display: 'One Conference',
      link: 'https://webconference.inet.co.th',
      privilege: 5,
      icon: 'https://cmp.one.th/img/oneid/bird1.png',
  },
  {
      display: 'One Box',
      link: 'https://box.one.th/login',
      privilege: 5,
      icon: 'https://box.one.th/img/icons/favicon-32x32.png',
  },
  {
      display: 'E-Form',
      link: 'https://eform.one.th/login',
      privilege: 5,
      icon: 'https://eform.one.th/e-form_logo.ico',
  },
];

export const AdminNavLinks: NavLink[] = [
  {
      display: 'ติดประกาศ',
      link: '/admin/announces',
      privilege: 5,
      icon: 'container',
      module: 'timestamp',
  },
  {
      display: 'ดูรายชื่อคนลงเวลา',
      link: '/admin/clockin',
      privilege: 5,
      icon: 'clock-circle',
      module: 'timestamp',
  },
  {
      display: 'ดูรายชื่อพื้นที่เสี่ยง',
      link: '/admin/covid19/report',
      privilege: 5,
      icon: 'exception',
      module: 'timestamp',
  },
  {
      display: 'จัดการพื้นที่เสี่ยง',
      link: '/admin/covid19',
      privilege: 5,
      icon: 'medicine-box',
      module: 'covid',
  },
  {
      display: 'จัดการข้อมูลบริษัท',
      link: '/admin/company',
      privilege: 5,
      icon: 'bank',
      module: 'timestamp',
  },
  {
      display: 'จัดการข้อมูลสาขา',
      link: '/admin/branch',
      privilege: 99,
      icon: 'branches',
      module: 'timestamp',
  },
  {
      display: 'จัดการการจอง',
      link: '/admin/reservation',
      privilege: 5,
      icon: 'schedule',
      module: 'reservation',
  },
  {
      display: 'ปรับแต่งรูป',
      link: '/admin/banner',
      privilege: 5,
      icon: 'picture',
      module: 'timestamp',
  },
  {
      display: 'จัดการระบบลา',
      link: '/admin/leaving',
      privilege: 5,
      icon: 'file-protect',
      module: 'leave',
  },
//   {
//         display: 'Report Task',
//         link: '/admin/worktask/report-task',
//         privilege: 5,
//         icon: 'book',
//         module: 'worktask',
//       },
];
// export interface NavLink {
//     display: string;
//     link: string;
//     privilege: number;
//     groups?: Array<string>;
//     isAdmin?: boolean;
//     icon: string;
//     module?: string;
// }

// // Normal user
// export const NavLinks: NavLink[] = [
//     {
//         display: 'บันทึกเวลาเข้า-ออกงาน',
//         link: '/clockin',
//         privilege: 5,
//         icon: 'clock-circle',
//         module: 'timestamp',
//     },
//     {
//         display: 'บันทึกงาน (Work Tasks)',
//         link: '/worktask',
//         privilege: 5,
//         icon: 'form',
//         module: 'worktask',
//     },
//     {
//         display: 'สรุปผลการตรวจสุขภาพ',
//         link: '/covid19',
//         privilege: 5,
//         icon: 'medicine-box',
//         module: 'covid',
//     },
//     {
//         display: 'ประกาศ',
//         link: '/announces',
//         privilege: 5,
//         icon: 'container',
//         module: 'timestamp',
//     },
//     {
//         display: 'ค้นหาพนักงาน',
//         link: '/staffs',
//         privilege: 5,
//         icon: 'team',
//         module: 'timestamp',
//     },
//     {
//         display: 'การจองทรัพย์สิน',
//         link: '/reservation',
//         privilege: 5,
//         icon: 'schedule',
//         module: 'reservation',
//     },
//     {
//         display: 'ระบบลา',
//         link: '/leaving/leave',
//         privilege: 5,
//         icon: 'file-protect',
//         module: 'leave',
//     },
// ];

// export const OneServiceLinks: NavLink[] = [
//     {
//         display: 'OneChat',
//         link: 'https://chat.one.th/login',
//         privilege: 5,
//         icon: 'https://chat.one.th/static/img/icons/logoonechat.png',
//     },
//     {
//         display: 'One Conference',
//         link: 'https://webconference.inet.co.th',
//         privilege: 5,
//         icon: 'https://cmp.one.th/img/oneid/bird1.png',
//     },
//     {
//         display: 'One Box',
//         link: 'https://box.one.th/login',
//         privilege: 5,
//         icon: 'https://box.one.th/img/icons/favicon-32x32.png',
//     },
//     {
//         display: 'E-Form',
//         link: 'https://eform.one.th/login',
//         privilege: 5,
//         icon: 'https://eform.one.th/e-form_logo.ico',
//     },
// ];

// export const AdminNavLinks: NavLink[] = [
//   {
//     display: 'ติดประกาศ',
//     link: '/admin/announces',
//     privilege: 5,
//     icon: 'container',
//     module: 'timestamp',
//   },
//   {
//     display: 'ดูรายชื่อคนลงเวลา',
//     link: '/admin/clockin',
//     privilege: 5,
//     icon: 'clock-circle',
//     module: 'timestamp',
//   },
//   {
//     display: 'จัดการข้อมูลบริษัท',
//     link: '/admin/company',
//     privilege: 5,
//     icon: 'bank',
//     module: 'timestamp',
//   },
//   {
//     display: 'จัดการการจอง',
//     link: '/admin/reservation',
//     privilege: 5,
//     icon: 'schedule',
//     module: 'reservation',
//   },
//   {
//     display: 'ปรับแต่งรูป',
//     link: '/admin/banner',
//     privilege: 5,
//     icon: 'picture',
//     module: 'timestamp',
//   },
//   {
//     display: 'จัดการระบบลา',
//     link: '/admin/leaving',
//     privilege: 5,
//     icon: 'file-protect',
//     module: 'leave',
//   },
//   {
//     display: 'Report Task',
//     link: '/admin/worktask/report-task',
//     privilege: 5,
//     icon: 'book',
//     module: 'worktask',
//   },
// ];
