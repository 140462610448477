import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ApiResult } from '../models/http.model';

@Injectable()
export class SystemService {
  constructor(private api: ApiService) { }
  private prefixUri = '/v1';

  private uri = (uri: string = ''): string => `${this.prefixUri}${uri}`;

  getEnableModules = (): Promise<ApiResult> => {
    return this.api.get(this.uri('/modules')).toPromise();
  }
}
