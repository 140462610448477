import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { generateSalt } from '../operators/util.operator';
import { ApiResult } from '../models/http.model';
import * as CryptoJS from 'crypto-js';
import { tap } from 'rxjs/operators';
import { StaffView, Account } from '../models/api.model';
import { LoginTicket } from '../models/auth.model';
import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';

export interface VerifyOtpResult {
  resource_key: string;
  token: string;
  type: string;
  is_admin: boolean;
}

@Injectable()
export class AuthService {
  private redirectUrl = '';
  private profile: Account;
  private organizes: Array<StaffView>;
  constructor(private api: ApiService, private http: HttpClient) { }

  private encryptSecret = (text: string, key: string): string =>
    CryptoJS.AES.encrypt(text, key).toString()

  isAdmin = (): boolean => localStorage.getItem('is_admin') === '1';

  setRedirectUrl = (url: string): void => {
    this.redirectUrl = url;
  }

  getSignInUrl = async (): Promise<string> => {
    return this.api
      .get(`/v1/signin/sso`).toPromise()
      .then((r) => r.data as string);
  }

  sendOtpCode = async (
    staffCode: string,
    reCaptChaToken: string
  ): Promise<ApiResult> => {
    const salt = generateSalt(32);
    const params = new HttpParams().append('salt', `${salt}`);
    const r = await this.api
      .get(`/v1/signin/ticket`, params)
      .toPromise()
      .then((result) => result.data);
    const secret = this.encryptSecret(staffCode, salt);
    const data = {
      secret,
      ticket: (r as LoginTicket).ticket,
      token: reCaptChaToken,
    } as object;
    return this.api
      .post(`/v1/signin/otp`, data)
      .pipe(
        tap((result) => {
          localStorage.setItem('salt', salt);
          localStorage.setItem('refCode', result.data);
        })
      )
      .toPromise();
  }

  verifyOtpCode = async (otpCode: number): Promise<ApiResult> => {
    const secret = this.encryptSecret(
      `${otpCode}`,
      localStorage.getItem('salt')
    );
    const data = {
      secret,
    } as object;
    const refCode = localStorage.getItem('refCode');
    const params = new HttpParams().append('refCode', `${refCode}`);
    return this.api
      .post(`/v1/signin/verify`, data, params)
      .pipe(
        tap(
          (r) => {
            const verified = r.data as VerifyOtpResult;
            localStorage.setItem('resource_key', verified.resource_key);
            localStorage.setItem('token', verified.token);
            localStorage.setItem('type', verified.type);
            if (verified.is_admin) {
              localStorage.setItem('is_admin', '1');
            }
          },
          () => {
            localStorage.clear();
          }
        )
      )
      .toPromise();
  }

  verifyByClientKey = async (
    clientKey: string,
    oneId: string
  ): Promise<ApiResult> => {
    const data = {
      client_key: clientKey,
      one_id: oneId,
    } as object;
    const params = new HttpParams().append('api_client', '1');
    return this.api
      .post(`/v1/signin/verify`, data, params)
      .pipe(
        tap(
          (r) => {
            const verified = r.data as VerifyOtpResult;
            localStorage.setItem('resource_key', verified.resource_key);
            localStorage.setItem('token', verified.token);
            localStorage.setItem('type', verified.type);
            if (verified.is_admin) {
              localStorage.setItem('is_admin', '1');
            }
          },
          () => {
            localStorage.clear();
          }
        )
      )
      .toPromise();
  }

  verifySSOAuthorizedCode = async (code: string, reCaptChaToken: string): Promise<ApiResult> => {
    const salt = generateSalt(32);
    const params = new HttpParams().append('salt', `${salt}`);
    const r = await this.api
      .get(`/v1/signin/ticket`, params)
      .toPromise()
      .then((result) => result.data);
    const secret = this.encryptSecret(code, salt);
    const data = {
      secret,
      ticket: (r as LoginTicket).ticket,
      token: reCaptChaToken,
    } as object;
    return this.api
      .post(`/v1/signin/sso`, data, params)
      .pipe(
        tap(
          (result) => {
            const verified = result.data as VerifyOtpResult;
            localStorage.setItem('resource_key', verified.resource_key);
            localStorage.setItem('token', verified.token);
            localStorage.setItem('type', verified.type);
            localStorage.setItem('sso', '1');
            if (verified.is_admin) {
              localStorage.setItem('is_admin', '1');
            }
          },
          () => {
            localStorage.clear();
          }
        )
      )
      .toPromise();
  }

  verifyByOneChatToken = async (
    onechatToken: string,
    clientId: string,
    reCaptChaToken: string
  ): Promise<ApiResult> => {
    const salt = generateSalt(32);
    const ticketParams = new HttpParams().append('salt', `${salt}`);
    const r = await this.api
      .get(`/v1/signin/ticket`, ticketParams)
      .toPromise()
      .then((result) => result.data);
    const data = {
      onechat_token: onechatToken,
      client_id: clientId,
      ticket: (r as LoginTicket).ticket,
      token: reCaptChaToken,
    } as object;
    const signInParams = new HttpParams().append('onechat', '1');
    return this.api
      .post(`/v1/signin/verify`, data, signInParams)
      .pipe(
        tap(
          (result) => {
            const verified = result.data as VerifyOtpResult;
            localStorage.setItem('resource_key', verified.resource_key);
            localStorage.setItem('token', verified.token);
            localStorage.setItem('type', verified.type);
            if (verified.is_admin) {
              localStorage.setItem('is_admin', '1');
            }
          },
          () => {
            localStorage.clear();
          }
        )
      )
      .toPromise();
  }

  verifyByNotifyToken = async (
    notifyToken: string,
    reCaptChaToken: string
  ): Promise<ApiResult> => {
    const salt = generateSalt(32);
    const ticketParams = new HttpParams().append('salt', `${salt}`);
    const r = await this.api
      .get(`/v1/signin/ticket`, ticketParams)
      .toPromise()
      .then((result) => result.data);
    const data = {
      notify_token: notifyToken,
      ticket: (r as LoginTicket).ticket,
      token: reCaptChaToken,
    } as object;
    const signInParams = new HttpParams().append('notify', '1');
    return this.api
      .post(`/v1/signin/verify`, data, signInParams)
      .pipe(
        tap(
          (result) => {
            const verified = result.data as VerifyOtpResult;
            localStorage.setItem('resource_key', verified.resource_key);
            localStorage.setItem('token', verified.token);
            localStorage.setItem('type', verified.type);
            if (verified.is_admin) {
              localStorage.setItem('is_admin', '1');
            }
          },
          () => {
            localStorage.clear();
          }
        )
      )
      .toPromise();
  }

  logout = async (): Promise<boolean> => {
    return this.api
      .delete(`/v1/signin`, null)
      .pipe(
        tap(() => {
          this.profile = null;
          this.isAdmin = null;
          this.redirectUrl = '';
          localStorage.clear();
        })
      )
      .toPromise()
      .then(() => true)
      .catch(() => false);
  }

  isAuthorizedAccess = async (): Promise<boolean> => {
    return this.api
      .get('/v1/signin')
      .toPromise()
      .then(() => true)
      .catch((err) => {
        localStorage.clear();
        return false;
      });
  }

  getMyProfile = async (): Promise<Account> => {
    if (this.profile) {
      return new Promise<Account>((resolve, reject) => {
        return resolve(this.profile);
      });
    }
    return this.api
      .get('/v1/profile')
      .pipe(
        tap((r) => {
          this.profile = r.data as Account;
        })
      )
      .toPromise()
      .then((r) => r.data as Account);
  }

  getMyOrganizes = async (): Promise<Array<StaffView>> => {
    if (this.organizes) {
      return new Promise<Array<StaffView>>((resolve, reject) => {
        return resolve(this.organizes);
      });
    }
    return this.api
      .get('/v1/profile/org')
      .pipe(
        tap((r) => {
          this.organizes = r.data as Array<StaffView>;
        })
      )
      .toPromise()
      .then((r) => r.data as Array<StaffView>);
  }
}
