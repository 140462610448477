import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { WINDOW_PROVIDERS } from './shared/providers/window.provider';

@NgModule({
  declarations: [AppComponent],
  imports: [AppRoutingModule, CoreModule],
  providers: [WINDOW_PROVIDERS],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule { }
