import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'ng-zorro-antd';

// Pipe
import { AvatarPipe } from './pipes/avatar.pipe';
import { AssetPipe } from './pipes/asset.pipe';
import { FileAttachPipe } from './pipes/attach.pipe';
import { FileSizePipe } from './pipes/filesize.pipe';
import { ReservePipe } from './pipes/reserve.pipe';

@NgModule({
  declarations: [
    AvatarPipe,
    AssetPipe,
    FileAttachPipe,
    FileSizePipe,
    ReservePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    AvatarPipe,
    AssetPipe,
    FileAttachPipe,
    FileSizePipe,
    ReservePipe,
  ]
})
export class SharedModule { }
