import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ApiResult } from 'src/app/shared/models/http.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class AnnouncesService {
  private prefixUri = '/v1/announces';
  private uri = (uri: string = ''): string => `${this.prefixUri}${uri}`;

  getAnnounces = async (critical: boolean = false): Promise<ApiResult> => {
    let params = new HttpParams();
    if (critical) {
      params = params.append('critical', '1');
    }
    return this.api.get(this.uri(), params).toPromise();
  }

  announceAcknowledge = async (id: string): Promise<ApiResult> => {
    return this.api.post(this.uri(`/${id}/ack`), null).toPromise();
  }

  constructor(private api: ApiService) { }
}
