import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileattach' })
export class FileAttachPipe implements PipeTransform {
    transform = (attachUid: string, taskUid: string, type: string = 'blob'): string => {
        const resourceKey = encodeURIComponent(localStorage.getItem('resource_key'));
        if (resourceKey) {
            return `/api/v1/worktask/${taskUid}/attach/${attachUid}?resource_key=${resourceKey}&type=${type}`;
        }
        return '';
    }
}
