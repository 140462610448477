<div nz-row style="min-height: 100vh;" nzType="flex" nzJustify="center">
  <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="8" nzXl="8">
    <nz-layout>
      <nz-drawer [nzClosable]="false" [nzVisible]="slideManuCollapsed" [nzPlacement]="'right'"
        (nzOnClose)="slideManuCollapsed = !slideManuCollapsed" [nzBodyStyle]="{'padding':'0px'}">
        <div class="inet-drawer-header"></div>
        <div class="header-profile" style="display: inline-flex;" *ngIf="profile">
          <nz-avatar nzIcon="user" nzSrc="{{ profile.uid | avatar }}" [nzShape]="'square'" [nzSize]="'large'">
          </nz-avatar>
          <div>
            <div style="width: 100%; margin: 0 8px;" clss="tbl-title">{{ profile.title_th }}
              {{ profile.first_name_th }}
              {{ profile.last_name_th }}</div>
            <div style="width: 100%; margin: 0 8px;" class="tbl-description">{{ profile.one_email }}</div>
          </div>
        </div>
        <nz-divider class="space" *ngIf="profile"></nz-divider>
        <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="slideManuCollapsed"
          style="width: 100%; padding: 0 18px;">
          <li nz-menu-item *ngFor="let l of navLinks" routerLink="{{ l.link }}"
            routerLinkActive="ant-menu-item-selected">
            <i nz-icon nzType="{{ l.icon }}" nzTheme="outline" style="margin-right: 14px;"></i>
            {{ l.display }}
          </li>
        </ul>
        <nz-divider class="space" *ngIf="enabledOneServiceLink"></nz-divider>
        <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="slideManuCollapsed"
          style="width: 100%; padding: 0 18px;" *ngIf="enabledOneServiceLink">
          <li nz-menu-group nzTitle="บริการอื่นใน One Platform">
            <div *ngFor="let l of oneServicesLinks; let li = index" style="padding: 0 14px;">
              <a href="{{ l.link }}" target="_blank" style="color: rgba(0,0,0,0.65);">
                <img src="{{ l.icon }}" width="24px" style="vertical-align: middle; margin-right: 8px;">
                {{ l.display }}
              </a>
            </div>
          </li>
        </ul>
        <nz-divider class="space" *ngIf="isAdmin | async"></nz-divider>
        <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="slideManuCollapsed"
          style="width: 100%; padding: 0 18px;" *ngIf="isAdmin | async">
          <li nz-menu-group nzTitle="สำหรับผู้ดูแลระบบ">
            <ul>
              <li nz-menu-item *ngFor="let l of adminLink" routerLink="{{ l.link }}"
                routerLinkActive="ant-menu-item-selected">
                <i nz-icon nzType="{{ l.icon }}" nzTheme="outline" style="margin-right: 14px;"></i>
                {{ l.display }}
              </li>
            </ul>
          </li>
        </ul>
        <nz-divider class="space"></nz-divider>
        <ul nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="slideManuCollapsed"
          style="width: 100%; padding: 0 18px;">
          <li nz-menu-item (click)="logout()">
            <i nz-icon nzType="logout" nzTheme="outline" style="margin-right: 14px;"></i> ออกจากระบบ
          </li>
        </ul>
      </nz-drawer>
      <nz-layout>
        <nz-header class="inet-header">
          <div class="logo"><a routerLink="/"><img src="/api/v1/assets/logo"
                style="height: 32px; margin: 0 24px;" /></a></div>
          <i class="trigger" style="line-height:48px;" nz-icon [nzType]="slideManuCollapsed ? 'menu' : 'menu'"
            (click)="slideManuCollapsed = !slideManuCollapsed"></i>
        </nz-header>
        <nz-content class="outer-content custom-layout">
          <router-outlet></router-outlet>
        </nz-content>
        <nz-footer>
          &copy;2020 INET Digital Outsource (KaaS)
          <div class="tbl-description">{{ version }}</div>
        </nz-footer>
      </nz-layout>
    </nz-layout>
  </div>
</div>

<ng-template #drawerTitleTpl>
  <div class="logo"></div>
</ng-template>

<nz-modal [(nzVisible)]="showAnnounce" [nzTitle]="announceTitleTpl" [nzFooter]="announceFooterTpl"
  *ngIf="showingAnnounce" [nzBodyStyle]="{'padding':'8px'}" [nzStyle]="{ top: '24px','border-radius':'0px' }">
  <div style="font-size: 16px; font-weight:600;">
    <div class="tbl-title">
      <nz-tag [nzColor]="'#f50'" *ngIf="showingAnnounce.priority==2">สำคัญมาก!</nz-tag><u>ประกาศ:
        {{ showingAnnounce.title }}</u>
    </div>
  </div>
  <div class="tbl-description">ประกาศเมื่อ
    {{ showingAnnounce.updated_at | date: 'EEE, MMM d, y, h:mm:ss a' }}</div>
  <img [src]="showingAnnounce.img_uid | asset" [ngStyle]="{ width: '100%' }" class="space">
  <div class="space tbl-description">มีผู้รับทราบประกาศนี้แล้ว {{ showingAnnounce.ack_count | number }} คน</div>
  <ng-template #announceTitleTpl>
    <i nz-icon [nzType]="'notification'" [nzTheme]="'twotone'" style="font-size: 24px;"
      [nzTwotoneColor]="'#eb2f96'"></i>
    ประกาศสำคัญจากบริษัท
  </ng-template>
  <ng-template #announceFooterTpl>
    <button nz-button nzType="primary" nzBlock (click)="ackAnnounce(showingAnnounce.uid)"><i nz-icon nzType="check"
        nzTheme="outline"></i>
      รับทราบประกาศ</button>
  </ng-template>
</nz-modal>