import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'avatar' })
export class AvatarPipe implements PipeTransform {
    transform = (id: string, isAccountId: boolean = false): string => {
        const resourceKey = encodeURIComponent(localStorage.getItem('resource_key'));
        if (resourceKey) {
            return `/api/v1/assets/avatar/${id}?resource_key=${resourceKey}&account_id=${isAccountId === true ? '1' : ''}`;
        }
        return '';
    }
}
