import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      try {
        if (typeof gtag === 'function') {
          if (event instanceof NavigationEnd) {
            gtag('set', 'page', event.urlAfterRedirects);
            gtag('send', 'pageview');
          }
        }
      } catch (e) { }
    });
  }
  /**
   * Emit google analytics event
   * Fire event example:
   * this.emitEvent("testCategory", "testAction", "testLabel", 10);
   */
  emitEvent = (
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) => {
    if (typeof gtag === 'function') {
      gtag('send', 'event', {
        eventCategory,
        eventLabel,
        eventAction,
        eventValue
      });
    }
  }
}
