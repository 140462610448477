import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { version } from '../../../../../package.json';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';
import { StaffView, Account, Announce } from 'src/app/shared/models/api.model';
import { NavLinks, AdminNavLinks, OneServiceLinks, NavLink } from './navigation-links';
import { of } from 'rxjs';
import { AnnouncesService } from 'src/app/shared/services/announces.service';
import { Router } from '@angular/router';
import { SystemService } from 'src/app/shared/services/system.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  profile: Account;
  organizes: Array<StaffView>;
  navLinks: Array<NavLink>;
  oneServicesLinks = OneServiceLinks;
  enabledOneServiceLink = false;
  adminLink: Array<NavLink>;
  slideManuCollapsed: boolean;
  version: string = version;
  isAdmin = of(this.auth.isAdmin()).toPromise();
  // isSSO = false;
  isOneChat = localStorage.getItem('isOneChat') === '1';

  criticalAnnounce: Array<Announce>;
  showAnnounce: boolean;
  showingAnnounce: Announce;
  constructor(
    private msg: NzMessageService,
    private auth: AuthService,
    private announceApi: AnnouncesService,
    private router: Router,
    private systemApi: SystemService,
  ) {
    // Get enable system modules
    this.navLinks = [] as Array<NavLink>;
    this.adminLink = [] as Array<NavLink>;
    this.systemApi.getEnableModules().then((r) => {
      // Navigation link
      NavLinks.forEach((v) => {
        if ((r.data as Array<string>).indexOf(v.module) >= 0) {
          this.navLinks.push({ ...v });
        }
      });
      // One service link
      if ((r.data as Array<string>).indexOf('oneservice') >= 0) {
        this.enabledOneServiceLink = true;
      }
      /// Admin link
      AdminNavLinks.forEach((v) => {
        if ((r.data as Array<string>).indexOf(v.module) >= 0) {
          this.adminLink.push({ ...v });
        }
      });
    });
  }

  ngOnInit(): void {
    this.appendGaTrackingCode();
    this.auth.getMyProfile().then((r) => (this.profile = r as Account));
    this.auth
      .getMyOrganizes()
      .then((r) => (this.organizes = r as Array<StaffView>));
    this.announceApi.getAnnounces(true).then((r) => {
      this.criticalAnnounce = r.data as Array<Announce>;
      if (this.criticalAnnounce && this.criticalAnnounce.length > 0) {
        this.showingAnnounce = this.criticalAnnounce.pop();
        this.showAnnounce = true;
      }
    });
  }

  private appendGaTrackingCode = (): void => {
    if (!environment.production) {
      return;
    }
    try {
      const gTagJs = document.createElement('script');
      gTagJs.async = true;
      gTagJs.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsKey}`;
      const script = document.createElement('script');
      script.innerHTML = `window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config','${environment.googleAnalyticsKey}');`;
      document.head.appendChild(gTagJs);
      document.head.appendChild(script);
    } catch (ex) { }
  }

  logout = (): void => {
    this.auth.logout().then((r) => {
      if (r) {
        this.msg.success('ออกจากระบบสำเร็จ');
        this.router.navigateByUrl('/auth');
        // if (this.isSSO) {
        //   const origin = window.location.origin;
        //   window.location.href = `https://one.th/api/oauth/logout?redirect_url=${origin}/auth`;
        // }
      } else {
        this.msg.error('ผิดพลาด กรุณาติดต่อเจ้าหน้าที่');
      }
    });
  }

  ackAnnounce = (id: string): void => {
    this.announceApi
      .announceAcknowledge(id)
      .then(() => {
        this.msg.success('ตอบรับประกาศสำเร็จ');
        if (this.criticalAnnounce && this.criticalAnnounce.length !== 0) {
          this.showingAnnounce = this.criticalAnnounce.pop();
        } else {
          this.showAnnounce = false;
          this.router.navigateByUrl('/');
        }
      })
      .catch(() => {
        this.msg.error('ผิดพลาด กรุณาติดต่อเจ้าหน้าที่');
      });
  }
}
