import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from 'src/app/core/layouts/main-layout/main-layout.component';
import { ErrorLayoutComponent } from 'src/app/core/layouts/error-layout/error-layout.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { AdminGuard } from 'src/app/core/guards/admin.guard';
import { Err404 } from 'src/app/shared/models/http.model';

const routes: Routes = [
  { path: '', redirectTo: 'clockin', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'clockin',
    loadChildren: () =>
      import('./features/clockin/clockin.module').then((m) => m.ClockinModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'announces',
    loadChildren: () =>
      import('./features/announces/announces.module').then((m) => m.AnnouncesModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'staffs',
    loadChildren: () =>
      import('./features/staffs/staffs.module').then((m) => m.StaffsModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'covid19',
    loadChildren: () =>
      import('./features/covid19/covid19.module').then((m) => m.Covid19Module),
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'worktask',
    loadChildren: () => import('./features/worktask/worktask.module').then(m => m.WorktaskModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'reservation',
    loadChildren: () => import('./features/reservation/reservation.module').then(
      m => m.ReservationModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'leaving',
    loadChildren: () => import('./features/leaving/leaving.module').then(
      m => m.LeavingModule),
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
  },
  { path: 'error', component: ErrorLayoutComponent, data: Err404 },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
