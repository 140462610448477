import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpError } from 'src/app/shared/models/http.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-error-layout',
  templateUrl: './error-layout.component.html',
  styleUrls: ['./error-layout.component.scss']
})
export class ErrorLayoutComponent implements OnInit {
  err: HttpError;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
  ) {
    if (this.router.getCurrentNavigation().extras.state !== undefined) {
      this.err = this.router.getCurrentNavigation().extras.state as HttpError;
    }
  }
  ngOnInit() {
    if (this.err === undefined) {
      this.activatedRoute.data.subscribe((err: HttpError) => {
        this.err = err;
      });
    }
  }
}
